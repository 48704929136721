import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";
import { IoIosWarning, IoMdOpen } from "react-icons/io";

import FlexiTimeLogo from "../images/logos/FlexiTime/FlexiTime_Full_White.svg";
import { Button, Container, Wrapper, Row, Box } from "../components/util";
import Layout from "../components/layout";
import Seo from "../components/seo";

const FullContainer = styled(Container)`
  height: calc(100vh - 114px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NotFoundPage = (props) => (
  <Layout lightNav>
    <Seo
      title="Oops... Page Not Found | 404"
      pathname={props.location.pathname}
    />
    <FullContainer bg="midnight" className="-light -whiteOut" graphicalBR>
      <Wrapper maxWidth={800} css={{ height: "auto" }}>
        <Box stackGap={35} className="-textCenter">
          <div>
            <h1 className="-fontLarge">404</h1>
            <h2 className="-fontLight">
              The page you're looking for doesn't exist{" "}
              <span role="img" aria-label="404 Page Not Found | Sad">
                😢
              </span>
            </h2>
          </div>
          <Button className="-lg primary -center" to="/">
            Go to Home
          </Button>
        </Box>
      </Wrapper>
    </FullContainer>
  </Layout>
);

export default NotFoundPage;
